<style lang="scss" scoped>
  .terms {
    h1 {
      font-family: "Circular Std";
      font-size: 1rem;
      line-height: 2;
      @media only screen and (min-width: 960px) {
        font-size: 1.333rem;
        line-height: 1.6;
      }
    }

    h2, h3, p, li {
      font-size: 0.75rem;
      line-height: 1.6;
      margin: 1.25rem 0;
    }

    h3 {
      font-family: "Circular Std";
    }
  }
</style>

<template>
  <Modal v-bind="$attrs" content-class="terms">
    <h1>
      Terms of Service
    </h1>
    <v-divider class="my-4" />

    <h2>1. Introduction</h2>
    <p>Welcome to homebuyer.com (the “Site”), operated by <strong>Novus Home Mortgage, a Division of Ixonia Bank</strong>. These Terms of Use (“Terms”) govern your access and use of the Site and any related services provided by <strong>Novus Home Mortgage</strong>  (collectively, the “Services”). By accessing the Site or using the Services, you agree to be bound by these Terms and the Privacy Policy, which together form the “Agreement.”</p>

    <h2>2. Services Provided</h2>
    <p><strong>Novus Home Mortgage</strong> offers mortgage lending services through the Site. The Services enable users to obtain information on mortgage products, apply for pre-approval, and complete loan applications. Any mortgage offers made through the Services are subject to verification of eligibility and the approval process.</p>

    <h3>2.1 Information on the Site</h3>
    <p>The information provided on the Site is for informational purposes only and does not constitute financial, legal, or professional advice. Mortgage products, rates, and services may change without notice. <strong>Novus Home Mortgage</strong> does not guarantee the accuracy or availability of any specific mortgage product or service listed on the Site.</p>

    <h2>3. User Obligations</h2>
    <h3>3.1 Eligibility</h3>
    <p>To use the Services, you must be at least 18 years old and legally able to enter into a binding contract. You must provide accurate and complete information when using the Services and comply with all applicable laws and regulations.</p>

    <h3>3.2 Application Process</h3>
    <p>When applying for a mortgage or pre-approval, you agree to provide accurate and truthful information. All loan applications are subject to <strong>Novus Home Mortgage’s</strong> verification and approval process, which may include a credit check and income verification.</p>

    <h2>4. Privacy and Security</h2>
    <h3>4.1 Privacy Policy</h3>
    <p>Your use of the Services is subject to our Privacy Policy. By using the Site, you consent to the collection and use of your information in accordance with the Privacy Policy, including the sharing of information necessary to process your mortgage application.</p>

    <h3>4.2 Data Protection</h3>
    <p><strong>Novus Home Mortgage</strong> employs security measures to protect your personal information. However, you are responsible for maintaining the confidentiality of your login credentials and for all activities under your account. Notify us immediately if you suspect unauthorized access to your account.</p>

    <h2>5. Mortgage Application Terms</h2>
    <h3>5.1 Pre-Qualification and Loan Offers</h3>
    <p>Pre-qualification does not guarantee loan approval. Final approval is subject to full underwriting review, including verification of credit, income, and other required documentation. <strong>Novus Home Mortgage</strong> reserves the right to modify or withdraw loan offers at any time prior to final approval.</p>

    <h3>5.2 Electronic Communication</h3>
    <p>By using the Site, you agree to receive communications electronically, including disclosures, notices, and updates regarding your mortgage application.</p>

    <h2>6. Prohibited Use</h2>
    <p>You may not use the Site for any illegal, fraudulent, or harmful activities. Unauthorized access or attempts to disrupt the Services or Site security are prohibited.</p>

    <h2>7. Changes to Services</h2>
    <p><strong>Novus Home Mortgage</strong> reserves the right to modify, suspend, or discontinue any part of the Services at any time, without notice. Continued use of the Services after changes are made constitutes your acceptance of the modified Terms.</p>

    <h2>8. Limitation of Liability</h2>
    <p>To the maximum extent permitted by law, <strong>Novus Home Mortgage</strong> will not be liable for any indirect, incidental, special, or consequential damages arising from the use of the Services, including but not limited to the loss of data, use, or profits.</p>

    <h2>9. Termination</h2>
    <p>You may terminate your account at any time by contacting <strong>Novus Home Mortgage</strong>. We may suspend or terminate your access to the Services if you breach these Terms or if required by law.</p>

    <h2>10. Governing Law</h2>
    <p>These Terms are governed by the laws of the state of Ohio, without regard to its conflict of law principles. Any legal actions arising from these Terms shall be resolved in state or federal courts located in Ohio.</p>

    <h2>11. Contact Information</h2>
    <p>If you have any questions about these Terms, please contact us at:</p>
    <ul>
      <li><strong>Phone:</strong> (844) 337-2548</li>
      <li><strong>Email:</strong> <a target="_blank" href="https://novushomemortgage.com/contact">https://novushomemortgage.com/contact</a></li>
    </ul>
  </Modal>
</template>
